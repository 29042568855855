<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="formData.name" :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('giveable_roles')">
                        <multi-selectbox
                            v-model="formData.giveable_roles" :options="roleOptions"
                        ></multi-selectbox>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('explanation')">
                        <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input v-model="formData.explanation" :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('explanation_en')">
                        <ValidationProvider name="explanation_en" rules="required" v-slot="{valid, errors}">
                            <b-input-group prepend="EN">
                                <b-form-input v-model="formData.explanation_en" :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('two_factor_authentication')">
                        <ValidationProvider name="two_factor" rules="required" v-slot="{valid, errors}">
                            <status-selectbox
                                v-model="formData.two_factor"
                                :valueType="'true'"

                                :validateError="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="createForm" :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import MultiSelectbox from '../../../components/interactive-fields/MultiSelectbox'
import RoleService from '@/services/RoleService'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox.vue";

export default {
    components: {
        StatusSelectbox,
        ValidationProvider,
        ValidationObserver,
        MultiSelectbox
    },
    data() {
        return {
            formData: {},
            roleOptions: [],
            formLoading: false
        }
    },
    methods: {
        async createForm() {
            let isValid = await this.$refs.formModalValidate.validate()

            if (isValid) {
                this.formLoading = true
                console.log(this.formData)

                RoleService.store(this.formData)
                    .then((response) => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$emit('createFormSuccess')
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate)
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        },
        getRoles() {
            this.roleOptions = []

            const config = {
                params: {
                    limit: -1
                }
            }

            RoleService.getAll(config)
                .then((response) => {
                    this.roleOptions = response?.data?.data?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                        ?.map((item) => {
                            return {
                                value: item.name,
                                text: this.getLocaleText(item, 'explanation')
                            }
                        }) ?? []

                })
                .catch((e) => {
                    this.showErrors(e)
                })
        }
    },
    created() {
        this.getRoles()
    }
}
</script>
